import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, catchError, filter, forkJoin, Observable, of, tap } from 'rxjs';
import { HOST_NAME } from 'src/app/global.tokens';
import { test_userDocumentsResponce, typeMapping, UserDocumentsResponse, DocumentData, Status, InputFile, sectionsData, TranslatedStatus, SectionCard, SectionCardDocument, SectionCardFile, SectionCardCookie, baseDocumentCookie, UserDocumentSection, SectionCardCookieElem, UserDocument, Stage } from './upload-documents.models';
import { CustomEncoder } from 'src/app/interceptors/encode-http.interceptor';
import { DOWNLOAD_DOCUMENT, GET_DOCUMENT, UPLOAD_DOCUMENTS, USER_DOCUMENTS } from '../http/base-http.service';
import { BaseUsermgrService } from '../http/base-usermgr.service';

@Injectable({
  providedIn: 'root'
})
export class UploadDocumentsService extends BaseUsermgrService {

  private userDocumentsResponse = new BehaviorSubject<UserDocumentsResponse>(null);
  private sectionCardsSubject = new BehaviorSubject<SectionCard[]>(sectionsData);
  private sectionCardsCookieKey = 'sectionCardsCookieKey';
  //  private sectionCardsCookieSubject = new BehaviorSubject<SectionCardCookie>(baseDocumentCookie); ?

  constructor(
    http: HttpClient,
    cookie: CookieService,
    @Inject(LOCALE_ID) public locale: string,
    @Inject(HOST_NAME) public hostName,
  ) {
    super(http, cookie, locale, hostName);


    //this.updateUserDocuments();
  }

  public updateUserDocuments(): void {
    this.getDocuments().pipe(
      tap((userDocumentsResponse) => this.userDocumentsResponse.next(userDocumentsResponse)),
      //tap((userDocumentsResponse) => this.userDocumentsResponse.next(test_userDocumentsResponce)),
      catchError(error => {
        console.error('Error fetching user documents', error);
        return of(null);
      })
    ).subscribe();
  }

  public getUserDocuments(): Observable<UserDocumentsResponse> {
    return this.userDocumentsResponse.asObservable().pipe(filter((userDocuments) => userDocuments !== null));
  }

  public updateSectionCards(sectionCards: SectionCard[]): void {
    this.sectionCardsSubject.next(sectionCards);
  }

  public getSectionCards(): Observable<SectionCard[]> {
    return this.sectionCardsSubject.asObservable().pipe(filter((sectionCards) => sectionCards !== null));
  }

  public updateSectionsCookie(sectionsCookie: SectionCardCookie): void {  //  loaded data going from last uploaded to hist in case if limit 
    localStorage?.setItem(this.sectionCardsCookieKey, JSON.stringify(sectionsCookie));
    //console.log(`* UploadDocumentsService updateSectionsCookie() new sectionsCookie`, this.getSectionsCookie())
  }

  public getSectionsCookie(): SectionCardCookie {
    return localStorage?.getItem(this.sectionCardsCookieKey) ? JSON.parse(localStorage?.getItem(this.sectionCardsCookieKey)) : baseDocumentCookie;
  }


  public isShowSections(userDocuments: UserDocumentsResponse): boolean {
    const hideTabContent = userDocuments.hide_tab_content;

    const hideNotRequired = Object.entries(userDocuments.hide_not_required)
      .some(([key, value]) => value !== true && userDocuments.status[key] !== Status.NotRequired);

    const allNotRequired = Object.entries(userDocuments.status)
      .every(([key, value]) => value === Status.NotRequired || userDocuments.hide_not_required[key]);

    return !(hideTabContent || !hideNotRequired || allNotRequired);
  }

  private filterDocuments(section: SectionCard, userDocuments: UserDocumentsResponse, filterFn: (document: UserDocument) => boolean) {
    return Array.isArray(userDocuments.documents[section.type])
      ? userDocuments.documents[section.type]
        .filter(filterFn)
        .map(({ status, name, created }, id) => ({ id, status, name, created }))
      : [];
  }

  private updateDocumentLabels(section: SectionCard, sectionCardsCookie: SectionCardCookie): SectionCardDocument[] {
    return section.card_documents.filter((doc) => {
      const cookieDoc = sectionCardsCookie.lastUploaded[section.type].elems.find((elem) => elem.name === doc.name);

      if (cookieDoc?.hide) return false;

      doc.label_id = cookieDoc ? cookieDoc.label_id : -1;

      return true;
    });
  }

  private assignStage(section: SectionCard) {
    const unTrakedDocuments = section.card_documents.filter((doc) => doc.label_id == -1 && [Status.Pending, Status.Approved].includes(doc.status)).length;
    section.card_files.forEach((card_file) => {
      card_file.load_value = 0; //  !

      section.card_documents.forEach((doc) => {
        if (card_file.id == doc.label_id && [Status.Pending, Status.Approved].includes(doc.status)) card_file.load_value++;
      });

      if (unTrakedDocuments > 0) card_file.load_value += card_file.load_value >= 1 ? Math.floor(unTrakedDocuments / section.card_files.length) : Math.ceil(unTrakedDocuments / section.card_files.length);

      card_file.stage = card_file.load_value >= section.max_section_load ? Stage.Hide : Stage.Show

    });
  }

  private setUpSection(section: SectionCard, userDocuments: UserDocumentsResponse, sectionCardsCookie: SectionCardCookie) {
    if (section.status == Status.Pending && [Status.Required, Status.Rejected, Status.Expired].includes(sectionCardsCookie.lastUploaded[section.type].status)) {
      //console.log(`* UploadDocumentsService setUpSection(): R -> P`, section.type)  /* show all docs with pen status + btn in case if some isnt laoded into last upl */

      section.card_documents = this.filterDocuments(section, userDocuments, (document) => document.status === Status.Pending);
      section.card_documents = this.updateDocumentLabels(section, sectionCardsCookie);

      this.assignStage(section)
    }

    // else if (section.status == Status.Approved && [Status.Required, Status.Rejected, Status.Expired].includes(sectionCardsCookie.lastUploaded[section.type].status)) {
    //   console.log(`* UploadDocumentsService setUpSection(): R -> A`, section.type)  /* show all docs with approved status => limit is double of max section load */
    // }

    else if ([Status.Required, Status.Rejected, Status.Expired].includes(section.status) && [Status.Required, Status.Rejected, Status.Expired].includes(sectionCardsCookie.lastUploaded[section.type].status)) {
      //console.log(`* UploadDocumentsService setUpSection(): R -> R`, section.type)  /* show all last loaded pen docs if exist + button if some is blocked */

      section.card_documents = this.filterDocuments(section, userDocuments, (document) => [Status.Required, Status.Rejected, Status.Expired, Status.Pending, Status.Approved].includes(document.status) &&
        sectionCardsCookie.lastUploaded[section.type].elems.some((elem) => elem.name === document.name)
      );
      section.card_documents = this.updateDocumentLabels(section, sectionCardsCookie);

      let lastLoaded = section.card_documents.filter((doc) => [Status.Pending, Status.Approved].includes(doc.status));
      let restDoc = section.card_documents.filter((doc) => ![Status.Pending, Status.Approved].includes(doc.status));

      section.card_documents = [...lastLoaded, ...restDoc].slice(0, section.max_section_load).reverse()

      this.assignStage(section)

    }

    else if (section.status == Status.Pending && sectionCardsCookie.lastUploaded[section.type].status == Status.Pending) {
      //console.log(`* UploadDocumentsService setUpSection(): P -> P`, section.type, sectionCardsCookie, userDocuments)  /* Just Uploaded, Wait Validation */

      section.card_documents = this.filterDocuments(section, userDocuments, (document) => [Status.Required, Status.Rejected, Status.Expired, Status.Pending, Status.Approved].includes(document.status) &&
        sectionCardsCookie.lastUploaded[section.type].elems.some((elem) => elem.name === document.name)
      );
      section.card_documents = this.updateDocumentLabels(section, sectionCardsCookie);

      let lastLoaded = section.card_documents.filter((doc) => [Status.Pending, Status.Approved].includes(doc.status));
      let restDoc = section.card_documents.filter((doc) => ![Status.Pending, Status.Approved].includes(doc.status));

      section.card_documents = [...lastLoaded, ...restDoc].slice(0, section.max_section_load * section.card_files.length).reverse()

      this.assignStage(section)
    }

    else if (section.status == Status.Approved && sectionCardsCookie.lastUploaded[section.type].status == Status.Pending) {
      //console.log(`* UploadDocumentsService setUpSection(): P -> A`, section.type)
      sectionCardsCookie.lastUploaded[section.type].status = Status.Approved

      section.card_documents = this.filterDocuments(section, userDocuments, (document) => document.status === Status.Approved);
      section.card_documents = this.updateDocumentLabels(section, sectionCardsCookie).slice(0, (section.max_section_load * section.card_files.length) + 2);

      section.card_files.forEach((card_file) => { card_file.stage = Stage.Hide });
    }

    else if ([Status.Required, Status.Rejected, Status.Expired].includes(section.status) && sectionCardsCookie.lastUploaded[section.type].status == Status.Pending) {
      //console.log(`* UploadDocumentsService setUpSection(): P -> R`, section.type, section)

      section.card_documents = this.filterDocuments(section, userDocuments, (document) => [Status.Required, Status.Rejected, Status.Expired, Status.Pending, Status.Approved].includes(document.status) &&
        sectionCardsCookie.lastUploaded[section.type].elems.some((elem) => elem.name === document.name)
      );
      section.card_documents = this.updateDocumentLabels(section, sectionCardsCookie);

      let lastLoaded = section.card_documents.filter((doc) => [Status.Pending, Status.Approved].includes(doc.status));
      let restDoc = section.card_documents.filter((doc) => ![Status.Pending, Status.Approved].includes(doc.status));

      section.card_documents = [...lastLoaded, ...restDoc].slice(0, section.max_section_load * section.card_files.length).reverse()

      this.assignStage(section)

    }

    else if (section.status == Status.Approved && sectionCardsCookie.lastUploaded[section.type].status == Status.Approved) {
      //console.log(`* UploadDocumentsService setUpSection(): A -> A`, section.type)

      section.card_documents = this.filterDocuments(section, userDocuments, (document) => document.status === Status.Approved);
      section.card_documents = this.updateDocumentLabels(section, sectionCardsCookie).slice(0, (section.max_section_load * section.card_files.length) + 2);

      section.card_files.forEach((card_file) => { card_file.stage = Stage.Hide });

    }

    else if ([Status.Required, Status.Rejected, Status.Expired].includes(section.status) && sectionCardsCookie.lastUploaded[section.type].status == Status.Approved) {
      //console.log(`* UploadDocumentsService setUpSection(): A -> R`, section.type)

      sectionCardsCookie.lastUploaded[section.type].elems.forEach((elem) => { sectionCardsCookie.history[section.type].elems.push(elem) });
      sectionCardsCookie.lastUploaded[section.type].elems = [];

      section.card_files.forEach((card_file) => { card_file.stage = Stage.Show });
    }

    else if (section.status == Status.Pending && sectionCardsCookie.lastUploaded[section.type].status == null) {
      //console.log(`* UploadDocumentsService setUpSection(): N -> P`, section.type)

      section.card_documents = this.filterDocuments(section, userDocuments, (document) => document.status === Status.Pending);
      section.card_documents = this.updateDocumentLabels(section, sectionCardsCookie);

      // section.card_documents.filter((document) => document.status == Status.Pending)
      //   .forEach((document) => sectionCardsCookie.lastUploaded[section.type].elems.push({label_id: document.id, name: document.name, hide: false, status: document.status}))

      this.assignStage(section)
    }

    else if (section.status == Status.Approved && sectionCardsCookie.lastUploaded[section.type].status == null) {
      //console.log(`* UploadDocumentsService setUpSection(): N -> A`, section.type)

      section.card_documents = this.filterDocuments(section, userDocuments, (document) => document.status === Status.Approved).slice(0, section.max_section_load * section.card_files.length * 2);
      section.card_documents = this.updateDocumentLabels(section, sectionCardsCookie);

      section.card_files.forEach((card_file) => { card_file.stage = Stage.Hide });
    }

    else if ([Status.Required, Status.Rejected, Status.Expired].includes(section.status) && sectionCardsCookie.lastUploaded[section.type].status == null) {
      //console.log(`* UploadDocumentsService setUpSection(): N -> R`, section.type)  /* All is to upload */

      sectionCardsCookie.lastUploaded[section.type].elems = [];
      sectionCardsCookie.lastUploaded[section.type].status = Status.Required;

      section.card_files.forEach((card_file) => { card_file.stage = Stage.Show });
    }

    return section

  }

  public fillSectionCards(sectionCards: SectionCard[], userDocuments: UserDocumentsResponse, sectionCardsCookie: SectionCardCookie): SectionCard[] {
    sectionCards.forEach((section) => {
      section.status = userDocuments.status[section.type];

      section = this.setUpSection(section, userDocuments, sectionCardsCookie)

      section.status = this.translateStatus(userDocuments.status[section.type]);
      section.card_documents.forEach((document) => { document.status = this.translateStatus(document.status) });
    });

    return sectionCards
  }

  createFileUploadHeaders(headers: {
    [name: string]: string | string[];
  } = {}): HttpHeaders {
    const newHeaders = {};
    Object.assign(newHeaders, headers);
    const csrfToken = this.cookie.get('csrftoken');
    newHeaders['Content-language'] = this.locale;
    newHeaders['Accept-Language'] = `pl-PL,${this.locale}`;
    newHeaders['x-translation-lang'] = this.locale?.slice(0, 2);
    if (csrfToken) {
      newHeaders['X-CSRFToken'] = csrfToken;
    }
    return new HttpHeaders(newHeaders);
  }

  uploadDocument(card_file: SectionCardFile) {
    const docForm = new FormData();
    const file = card_file.file;

    docForm.append('document_type', card_file.type);
    docForm.append('file-1', file);

    const httpOptions = {
      headers: this.createFileUploadHeaders(),
      withCredentials: true
    };

    console.log(docForm.get('file-1'), docForm.get('document_type'));
    //return of(true)
    return this.http.post<any>(this.apiUrl + UPLOAD_DOCUMENTS, docForm, httpOptions);
  }

  getDocuments() {
    return this.get<any>(USER_DOCUMENTS);
  }

  getDocument(docId: string) {
    const url = GET_DOCUMENT + docId
    return this.get<any>(url)
  }

  downloadDocument(docId: string) {
    const url = DOWNLOAD_DOCUMENT + docId
    return this.get<any>(url)
  }

  translateStatus(status: Status): string {
    const translationMap: { [key in Status]: string } = {
      [Status.NotRequired]: TranslatedStatus.NotRequired,
      [Status.Required]: TranslatedStatus.Required,
      [Status.Approved]: TranslatedStatus.Approved,
      [Status.Pending]: TranslatedStatus.Pending,
      [Status.Rejected]: TranslatedStatus.Rejected,
      [Status.Expired]: TranslatedStatus.Expired,
      [Status.Delete]: TranslatedStatus.Delete,
    };

    return translationMap[status];
  }

  translateBackStatus(translatedStatus: string): Status | undefined {
    const reverseTranslationMap = new Map<string, Status>([
      [TranslatedStatus.NotRequired, Status.NotRequired],
      [TranslatedStatus.Required, Status.Required],
      [TranslatedStatus.Approved, Status.Approved],
      [TranslatedStatus.Pending, Status.Pending],
      [TranslatedStatus.Rejected, Status.Rejected],
      [TranslatedStatus.Expired, Status.Expired],
      [TranslatedStatus.Delete, Status.Delete],
    ]);

    return reverseTranslationMap.get(translatedStatus);
  }

}
