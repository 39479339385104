

/* ------------------------------------------------------------------------------------------------------------------------------------------------------ */

/* section status */
export enum Status {
  NotRequired = 'not_required',
  Required = 'required',
  Approved = 'approved',
  Pending = 'pending',
  Rejected = 'rejected',
  Expired = 'expired',
  Delete = 'delete'
}

export enum Stage {
  Hide = -1,
  Show = 0,
  Loading = 1,
}

export const sameFileError = $localize`:@@uploadDocuments.sameFileErr:You cannot upload the same file again. Please choose a different file.`;

export const TranslatedStatus = {
  NotRequired: $localize`:@@uploadDocuments.NotRequiredStatus:not_required`,
  Required: $localize`:@@uploadDocuments.RequiredStatus:required`,
  Approved: $localize`:@@uploadDocuments.ApprovedStatus:approved`,
  Pending: $localize`:@@uploadDocuments.PendingStatus:pending`,
  Rejected: $localize`:@@uploadDocuments.RejectedStatus:rejected`,
  Expired: $localize`:@@uploadDocuments.ExpiredStatus:expired`,
  Delete: `delete`,
}

/* section type */
export enum Type {
  ID = 'i',
  Passport = 'e',
  PhotoWithId = 'h',
  PaymentMethod = 'm',
  AddressProof = 'p',
  SourceOfFunds = 's',
  Other = 'o',
}

export enum SectionType {
  ID = 'i',
  Passport = 'e',
  PhotoWithId = 'h',
  PaymentMethod = 'm',
  AddressProof = 'p',
  SourceOfFunds = 's',
  Other = 'o',
}

export const typeMapping = {
  'i': Type.ID,
  'e': Type.Passport,
  'h': Type.PhotoWithId,
  'm': Type.PaymentMethod,
  's': Type.SourceOfFunds,
  'p': Type.AddressProof,
  'o': Type.Other,
};

/* single or standalone doc */
export interface Document extends UserDocument {
  description?: string;
}

export interface InputFile {
  index: number,
  barValue: number,
  description?: string;
  displayLoadBar: boolean,

  type: string;
  file?: File,
}

/* document section */
export interface DocumentSection {
  title: string;
  description: { full: string, short?: string };
  icon: string;
  attempt: number;

  status?: Status | string;
  type?: string;
  hide?: boolean;
  disable?: boolean;
  isExpanded: boolean;

  documents?: Document[];
  files: InputFile[];
}

/* whole Data */
export interface DocumentData {
  sections: DocumentSection[];
}

export interface UserDocument {
  name?: string, 
  created?: string, 
  status?: Status, 
  status_display?: string,
}

export interface UserDocumentSection {
  [key: string]: UserDocument[],
}

export interface UserDocumentIsHide {
  [key: string]: boolean;
}

export interface UserDocumentSectionStatus {
  [key: string]: Status;
}

export interface UserDocumentsResponse {
  documents?: UserDocumentSection,
  hide_not_required?: UserDocumentIsHide,
  hide_tab_content?: boolean,
  status?: UserDocumentSectionStatus,
  user_country?: string,
}

/* ------------------------------------------------------------------------------------------------------------------------------------------------------ */

export const test_userDocumentsResponce: UserDocumentsResponse = {
  documents:
    { 
    'i': [
      { 
        created: "2024-02-6 10:57:31.417371+00:00",
        name: "err3.png",
        status: Status.Approved,
        status_display: "Approved",
      },
      { 
        created: "2024-02-6 10:57:31.417371+00:00",
        name: "err2.png",
        status: Status.Approved,
        status_display: "Approved",
      },
      { 
        created: "2024-02-6 10:57:31.417371+00:00",
        name: "err1.png",
        status: Status.Rejected,
        status_display: "Rejected",
      },
      { 
        created: "2023-09-29 10:57:31.417371+00:00",
        name: "err.png",
        status: Status.Rejected,
        status_display: "Rejected",
      },
      { 
        created: "2024-02-6 10:57:31.417371+00:00",
        name: "somefoto3.png",
        status: Status.Approved,
        status_display: "Approved",
      },
      { 
        created: "2024-02-6 10:57:31.417371+00:00",
        name: "somefoto2.png",
        status: Status.Approved,
        status_display: "Approved",
      },
      { 
        created: "2024-02-6 10:57:31.417371+00:00",
        name: "somefoto1.png",
        status: Status.Approved,
        status_display: "Approved",
      },
      { 
        created: "2023-09-29 10:57:31.417371+00:00",
        name: "somefoto.png",
        status: Status.Approved,
        status_display: "Approved",
      },
      { 
        created: "2024-02-6 10:57:31.417371+00:00",
        name: "somefoto-2.png",
        status: Status.Approved,
        status_display: "Approved",
      },
      { 
        created: "2023-09-29 10:57:31.417371+00:00",
        name: "somefoto-1.png",
        status: Status.Approved,
        status_display: "Approved",
      }
    ],
    'e': [
      { 
        created: "2024-02-6 10:57:31.417371+00:00",
        name: "app.comp.png",
        status: Status.Pending,
        status_display: "Pending",
      },
      { 
        created: "2024-02-6 10:57:31.417371+00:00",
        name: "err2.png",
        status: Status.Rejected,
        status_display: "Rejected",
      },
      { 
        created: "2024-02-6 10:57:31.417371+00:00",
        name: "err1.png",
        status: Status.Pending,
        status_display: "Pending",
      },
      { 
        created: "2024-02-6 10:57:31.417371+00:00",
        name: "somefoto3.png",
        status: Status.Approved,
        status_display: "Approved",
      },
      { 
        created: "2024-02-6 10:57:31.417371+00:00",
        name: "somefoto2.png",
        status: Status.Approved,
        status_display: "Approved",
      },
      { 
        created: "2024-02-6 10:57:31.417371+00:00",
        name: "somefoto1.png",
        status: Status.Approved,
        status_display: "Approved",
      },
      { 
        created: "2023-09-29 10:57:31.417371+00:00",
        name: "somefoto.png",
        status: Status.Approved,
        status_display: "Approved",
      }
    ],
    'h': [
      { 
        created: "2024-02-6 10:57:31.417371+00:00",
        name: "err2.png",
        status: Status.Approved,
        status_display: "Approved",
      },
      { 
        created: "2024-02-6 10:57:31.417371+00:00",
        name: "err1.png",
        status: Status.Rejected,
        status_display: "Rejected",
      },
      { 
        created: "2023-09-29 10:57:31.417371+00:00",
        name: "err.png",
        status: Status.Approved,
        status_display: "Pending",
      }
    ],
    'm': [
      { 
        created: "2024-02-6 10:57:31.417371+00:00",
        name: "err3.png",
        status: Status.Approved,
        status_display: "Approved",
      },
      { 
        created: "2024-02-6 10:57:31.417371+00:00",
        name: "err2.png",
        status: Status.Approved,
        status_display: "Approved",
      },
      { 
        created: "2024-02-6 10:57:31.417371+00:00",
        name: "err1.png",
        status: Status.Approved,
        status_display: "Approved",
      },
      { 
        created: "2023-09-29 10:57:31.417371+00:00",
        name: "err.png",
        status: Status.Rejected,
        status_display: "Rejected",
      },
      { 
        created: "2023-09-29 10:57:31.417371+00:00",
        name: "somefoto2.png",
        status: Status.Approved,
        status_display: "Approved",
      },
      { 
        created: "2024-02-6 10:57:31.417371+00:00",
        name: "somefoto1.png",
        status: Status.Approved,
        status_display: "Approved",
      },
      { 
        created: "2023-09-29 10:57:31.417371+00:00",
        name: "somefoto.png",
        status: Status.Approved,
        status_display: "Approved",
      }
    ],
    's': [

    ],
    'o': [

    ]
  },

  hide_not_required: { 'i': false, 'e': false, 'h': false, 'm': false, 'p': false, 's': false, 'o': false },
  status: { 'i': Status.Required, 'e': Status.Pending, 'h': Status.Approved, 'm': Status.Approved, 'p': Status.Required, 's': Status.Required, 'o': Status.NotRequired },

  hide_tab_content: false,
  user_country: "PL"
};

/* ------------------------------------------------------------------------------------------------------------------------------------------------------ */

export interface SectionCardCookieElem {
  label_id: number,
  name: string,
  status: string,
  hide: boolean,
}

export interface SectionCardCookie {
  lastUploaded: { [key: string]: { status?: Status, elems: SectionCardCookieElem[] } },
  history: { [key: string]: { status?: Status, elems: SectionCardCookieElem[] } },
}

export interface SectionCardFile {
  id: number,
  stage: number,
  file?: File,
  type?: SectionType,
  bar_value: number,
  send_value: number,
  load_value: number,
  description: string,
}

export interface SectionCardDocument {
  id: number,
  label_id?: number,
  status: any,
  name: string,
  created: string,
}

export interface SectionCardTitle {
  index?: number,
  status?: any,
  title: string,
  description: string,
  icon: string,
}

export interface SectionCard {
  id: number,
  is_hide?: boolean,
  type: SectionType,
  status?: any,
  max_section_load: number,
  max_section_send: number,
  card_title: SectionCardTitle,
  card_documents: SectionCardDocument[],
  card_files: SectionCardFile[],
}

export const sectionsData: SectionCard[] = [
  {
    id: 0,
    type: SectionType.ID,
    max_section_load: 1,
    max_section_send: 8,
    card_title: {
      title: $localize`:@@uploadDocuments.IDLabel:ID`,
      description: $localize`:@@uploadDocuments.idDescription:Make sure the photo is sharp and all four corners of the document are visible.`,
      icon: "assets/icons/icon-upload-id.svg",
    },
    card_documents: [],
    card_files: [
      {
        id: 0,
        stage: 0,
        bar_value: 0,
        send_value: 0,
        load_value: 0,
        description: $localize`:@@uploadDocuments.twinFirstDescriptionList:Please upload front side of the document.` 
      },
      {
        id: 1,
        stage: 0,
        bar_value: 0,
        send_value: 0,
        load_value: 0,
        description: $localize`:@@uploadDocuments.twinSecondDescriptionList:Please upload the back side of the document.`
      }
    ],
  },
  {
    id: 1,
    type: SectionType.Passport,
    max_section_load: 2,
    max_section_send: 8,
    card_title: {
      title: $localize`:@@uploadDocuments.passportLabel:Passport`,
      description: $localize`:@@uploadDocuments.passportDescription:Make sure the photo is sharp and all four corners of the document are visible.`,
      icon: "assets/icons/icon-doc-passport.svg",
    },
    card_documents: [],
    card_files: [
      {
        id: 0,
        stage: 0,
        bar_value: 0,
        send_value: 0,
        load_value: 0,
        description: $localize`:@@uploadDocuments.passportDescription:Make sure the photo is sharp and all four corners of the document are visible.` 
      },
    ],
  },
  {
    id: 2,
    type: SectionType.PhotoWithId,
    max_section_load: 1,
    max_section_send: 8,
    card_title: {
      title: $localize`:@@uploadDocuments.photoIdLabel:Photo with holding ID`,
      description: $localize`:@@uploadDocuments.selfIDescription:Send a selfie that includes a photo of your figure from the waist up and holding an identification document in your hand, so that the photo and the data on the document are visible and legible.`,
      icon: "assets/icons/icon-doc-selfie-doc.svg",
    },
    card_documents: [],
    card_files: [
      {
        id: 0,
        stage: 0,
        bar_value: 0,
        send_value: 0,
        load_value: 0,
        description: $localize`:@@uploadDocuments.passportDescription:Make sure the photo is sharp and all four corners of the document are visible.` 
      },
    ],
  },
  {
    id: 3,
    type: SectionType.PaymentMethod,
    max_section_load: 4,
    max_section_send: 8,
    card_title: {
      title: $localize`:@@uploadDocuments.paymentMethodLabel:Payment Method`,
      description: $localize`:@@uploadDocuments.paymentDescription:Send a document confirming the payment to our service. This can be a transfer confirmation (PDF), a photo of the payment card in case of a card payment, or a bank statement with the visible payment.`,
      icon: "assets/icons/icon-doc-paymeny-in.svg",
    },
    card_documents: [],
    card_files: [
      {
        id: 0,
        stage: 0,
        bar_value: 0,
        send_value: 0,
        load_value: 0,
        description: $localize`:@@uploadDocuments.passportDescription:Make sure the photo is sharp and all four corners of the document are visible.` 
      },
    ],
  },
  {
    id: 4,
    type: SectionType.AddressProof,
    max_section_load: 1,
    max_section_send: 8,
    card_title: {
      title: $localize`:@@uploadDocuments.addressLabel:Proof of Address`,
      description: $localize`:@@uploadDocuments.addressDescription:A bank confirmation of the last payment to our service with the visible address and your first and last name, a utility bill - water, electricity, gas, council tax, a bank statement containing address details and your first and last name. PDF format required.`,
      icon: "assets/icons/icon-upload-address.svg",
    },
    card_documents: [],
    card_files: [
      {
        id: 0,
        stage: 0,
        bar_value: 0,
        send_value: 0,
        load_value: 0,
        description: $localize`:@@uploadDocuments.passportDescription:Make sure the photo is sharp and all four corners of the document are visible.` 
      },
    ],
  },
  {
    id: 5,
    type: SectionType.SourceOfFunds,
    max_section_load: 1,
    max_section_send: 8,
    card_title: {
      title: $localize`:@@uploadDocuments.sofLabel:Source of Funds`,
      description: $localize`:@@uploadDocuments.sourceFundsDescription:A bank statement with visible income, tax declaration or any other document confirming yoyr incomes.`,
      icon: "assets/icons/icon-doc-funds.svg",
    },
    card_documents: [],
    card_files: [
      {
        id: 0,
        stage: 0,
        bar_value: 0,
        send_value: 0,
        load_value: 0,
        description: $localize`:@@uploadDocuments.passportDescription:Make sure the photo is sharp and all four corners of the document are visible.` 
      },
    ],
  },
];

export const baseDocumentCookie: SectionCardCookie = {
  lastUploaded: {
    'i': { status: null, elems: [] },
    'e': { status: null, elems: [] },
    'h': { status: null, elems: [] },
    'm': { status: null, elems: [] },
    's': { status: null, elems: [] },
    'p': { status: null, elems: [] },
    'o': { status: null, elems: [] },
  },
  history: {
    'i': { status: null, elems: [] },
    'e': { status: null, elems: [] },
    'h': { status: null, elems: [] },
    'm': { status: null, elems: [] },
    's': { status: null, elems: [] },
    'p': { status: null, elems: [] },
    'o': { status: null, elems: [] },
  }
}

/* ------------------------------------------------------------------------------------------------------------------------------------------------------ */

export const documentData: DocumentData = {
  sections: [
    {
      title: $localize`:@@uploadDocuments._IDLabel:ID Card`,
      description: { full: $localize`:@@uploadDocuments._idDescription:Ensure sharp photo with all document corners visible.`, short: 'Some text'},
      type: Type.ID,
      icon: "assets/icons/icon-upload-id.svg",
      attempt: 0,
      isExpanded: true,
      files: [
        { index: 0, displayLoadBar: false, barValue: 10, type: Type.ID, description: $localize`:@@uploadDocuments._twinFirstDescriptionList:Upload front side of your document`, file: null },
        { index: 1, displayLoadBar: false, barValue: 10, type: Type.ID, description: $localize`:@@uploadDocuments._twinSecondDescriptionList:Upload back side of your document`, file: null },
      ]
    },
    {
      title: $localize`:@@uploadDocuments._passportLabel:Passport`,
      description: { full: $localize`:@@uploadDocuments._passportDescription:Ensure sharp photo with all document corners visible.`, short: "Some text"},
      type: Type.Passport,
      icon: "assets/icons/icon-doc-passport.svg",
      attempt: 0,
      isExpanded: true,
      files: [
        { index: 0, displayLoadBar: false, barValue: 10, type: Type.Passport, file: null, description: $localize`:@@uploadDocuments._twinFirstDescriptionList:Upload front side of your document` },
      ]
    },
    {
      title: $localize`:@@uploadDocuments._photoIdLabel:Photo with holding ID`,
      description: { full: $localize`:@@uploadDocuments._selfIDescription:Send a selfie holding an ID document, waist up, with visible and legible data.`, short: 'Some text'},
      type: Type.PhotoWithId,
      icon: "assets/icons/icon-doc-selfie-doc.svg",
      attempt: 0,
      isExpanded: true,
      files: [
        { index: 0, displayLoadBar: false, barValue: 10, type: Type.PhotoWithId, file: null, description: $localize`:@@uploadDocuments._twinFirstDescriptionList:Upload front side of your document` },
      ]
    },
    {
      title: $localize`:@@uploadDocuments.paymentMethodLabel:Payment Method`,
      description: { full: $localize`:@@uploadDocuments._paymentDescription:Send a document confirming the payment (transfer confirmation, a photo of the payment card, or a bank statement with the visible payment).`, short: 'Some text' },
      type: Type.PaymentMethod,
      icon: "assets/icons/icon-doc-paymeny-in.svg",
      attempt: 0,
      isExpanded: true,
      files: [
        { index: 0, displayLoadBar: false, barValue: 10, type: Type.PaymentMethod, file: null, description: $localize`:@@uploadDocuments._twinFirstDescriptionList:Upload front side of your document` },
      ]
    },
    {
      title: $localize`:@@uploadDocuments.addressLabel:Proof of Address`,
      description: { full: $localize`:@@uploadDocuments._addressDescription:Send a document confirming your address (a utility bill - water, electricity, gas, council tax, a bank statement). The document can't be older then three month.`, short: 'Some text' },
      type: Type.AddressProof,
      icon: "assets/icons/icon-upload-address.svg",
      attempt: 0,
      isExpanded: true,
      files: [
        { index: 0, displayLoadBar: false, barValue: 10, type: Type.AddressProof, file: null, description: $localize`:@@uploadDocuments._twinFirstDescriptionList:Upload front side of your document` },
      ]
    },
    {
      title: $localize`:@@uploadDocuments.sofLabel:Source of Funds`,
      description: { full: $localize`:@@uploadDocuments._sourceFundsDescription:Send a document confirming your source of funds (a bank statement for the last 3 months showing income from salaries, dividends, sale of shares, a tax declaration, a receipt from the sale of a tangible asset, etc).`, short: 'Some text'},
      type: Type.SourceOfFunds,
      icon: "assets/icons/icon-doc-funds.svg",
      attempt: 0,
      isExpanded: true,
      files: [
        { index: 0, displayLoadBar: false, barValue: 10, type: Type.SourceOfFunds, file: null, description: $localize`:@@uploadDocuments._twinFirstDescriptionList:Upload front side of your document` },
      ]
    },
/*     {
      title: "Other",
      description: { full: "Some large Other doc description which contain very usssefulll information."},
      type: Type.Other,
      icon: "assets/icons/icon-upload-id.svg",
      files: [
        { index: 0, displayLoadBar: false, barValue: 10, type: Type.Other },
      ]
    }, */
  ]
};

export const COMMON_FILE_NAMES = new Set([
  "image.jpg",
  "photo.jpg",
  "screenshot.png",
  "picture.jpg",
  "img.jpg",
  "image.png",
  "photo.png",
  "screenshot.jpg"
]);